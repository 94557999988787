<template>
    <div class="login-comp">
        <!-- STEP - 1 -->
        <div class="step" v-if="step == 0">
            <div class="form-input-title">
                <i class="ri-phone-line" style="margin-left:5px;"></i>
                <p>מספר פלאפון נייד</p>
            </div>
            <div class="form-input">
                <div class="kidomet">
                    <p>972+</p>
                </div>
                <el-input v-model="phone_number" placeholder="מספר פלאפון" type="tel" name="phone"
                    :input-style="input_style" maxlength="10" />
            </div>
            <el-button type="primary" :disabled="enable_next" @click="send_code">המשך</el-button>
        </div>
        <!-- STEP - 2 -->
        <div class="step" v-if="step == 1">
            <div class="form-input-title">
                <i class="ri-phone-lock-line" style="margin-left:5px;"></i>
                <p>קוד אימות</p>
            </div>

            <div class="form-input">
                <el-input v-model="login_code" placeholder="קוד אימות" type="tel" name="phone"
                    :input-style="input_style" maxlength="6" />
            </div>

            <el-button type="primary" :disabled="enable_next" @click="login">המשך</el-button>
        </div>
        <div class="recaptcha-container" id="recaptcha-container" ref="recaptcha_ref" :key="recaptchaKey">
        </div>
    </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import store from '../../store'
import { projectAuth, projectAuthVer, projectFunctions } from '../../firebase/config';
import { login_log } from '../../functions/Melaket_logs'

export default {
    setup() {
        const recaptchaKey = ref(Date.now());
        const recaptcha_ref = ref(null)

        const user = ref(computed(() => {
            return store.state.user
        }))
        const loading = ref(true);

        const step = ref(0)
        const phone_number = ref(null);
        const pending_code = ref(null);
        const login_code = ref(null);

        const input_style = {
            'border': 'none',
            'background': 'var(--btnBg)',
            'font-size': '18px',
            'color': 'white'
        }

        const enable_next = ref(computed(() => {
            if (step.value == 0) {
                if (phone_number.value && phone_number.value.length == 10) {
                    return false
                } else {
                    return true
                }
            }
            if (step.value == 1) {
                if (login_code.value && login_code.value.length == 6) {
                    return false
                } else {
                    return true
                }
            }
        }))


        const set_captcha = () => {
            const el = document.getElementById("recaptcha-container")
            if (el && !user.value) {
                try {
                    if (window.recaptchaVerifier) {
                        console.log(window.recaptchaVerifier);
                        window.recaptchaVerifier.clear();
                        window.recaptchaVerifier = null;
                    }
                    window.recaptchaVerifier = new projectAuthVer.RecaptchaVerifier('recaptcha-container', {
                        'size': 'normal',
                        'callback': (response) => {
                        },
                        'expired-callback': () => {
                            console.log("THIS IS THE PROBLEM!");
                            recaptchaKey.value = new Date();
                        }
                    })
                    window.recaptchaVerifier.render().catch(error => {
                        // Handle asynchronous error if render() returns a promise.
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error)
                }
            }
        }


        const send_code = async () => {
            const appVerifier = window.recaptchaVerifier;
            var p_n = '+972' + phone_number.value
            projectAuth.signInWithPhoneNumber(p_n, appVerifier).then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                pending_code.value = true;
                step.value = 1;
                const el = document.getElementById("recaptcha-container")
                if (el) {
                    el.style.visibility = 'hidden';
                }
            })
        }
        //0542423503

        const login = async () => {
            confirmationResult.confirm(login_code.value).then(async (result) => {
                // User signed in successfully.
                const now = new Date().getTime();
                localStorage.setItem('lastRunTimestamp', now.toString());
                localStorage.setItem('lastAction', 'login');

                try {
                    console.log('unlocking in db');
                    const res = await projectFunctions.httpsCallable('profile_locked')({ user: result.user.uid, locked: false });
                    if (res.data === true) {
                        console.log('user unlocked successfully')
                    } else {
                        console.log('functions error in unlocking user', res)
                    }
                } catch (error) {
                    console.log('failed to update db', error);
                }

                if (window.recaptchaVerifier) {
                    window.recaptchaVerifier.clear();
                    window.recaptchaVerifier = null;
                }
                router.replace('/');
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
            });
        }

        function whenElementLoaded(id, callback) {
            // Check if the element is already in the DOM
            if (document.getElementById(id)) {
                callback();
                return;
            }

            // If not, set up a MutationObserver to wait for it
            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (document.getElementById(id)) {
                        callback();
                        observer.disconnect();  // Stop observing once the callback has been called
                    }
                });
            });

            observer.observe(document.body, {
                childList: true,      // observe direct children of the body
                subtree: true,        // also observe all descendants
                attributes: false,    // don't need to observe attributes
                characterData: false, // or character data
            });
        }

        whenElementLoaded('recaptcha-container', () => {
            console.log('Element has been loaded into the DOM!');
            set_captcha();
        });

        return {
            step, user, phone_number, input_style, enable_next, send_code,
            pending_code, login_code, login, loading, recaptcha_ref, recaptchaKey
        }
    }
}
</script>

<style scoped>
.login-comp {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
}

.form-input-title {
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin-top: 10px;
    color: white;
    text-shadow: 0 0 3px black;
    margin-bottom: 5px;
    overflow: hidden;
}

.form-input {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
}

.kidomet {
    position: absolute;
    top: 5px;
    left: 0;
    width: fit-content;
    height: fit-content;
    font-size: 18px;
    color: whitesmoke;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.step {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
}

.recaptcha-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
</style>