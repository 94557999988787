import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyDdHgIvGcqZXqiYf4UJq9bq1yfb6K5oU4Q",
    authDomain: "wms-biz.firebaseapp.com",
    projectId: "wms-biz",
    storageBucket: "wms-biz.appspot.com",
    messagingSenderId: "214757223510",
    appId: "1:214757223510:web:d2f716a94bdf5a04391ff7",
    measurementId: "G-D51T8KHNGX"
};
//init
// init firebase
var myApp = firebase.initializeApp(firebaseConfig);

// init services 

const projectFirestore = myApp.firestore();
const projectAuth = myApp.auth();
// projectAuth.setPersistence('session');

const projectAuthVer = firebase.auth;
const projectStorage = myApp.storage();


const projectFunctions= myApp.functions('europe-west2');

// projectFunctions.useEmulator("localhost", 5001);

const FireStore = firebase.firestore;
const fieldValue = firebase.firestore.FieldValue;

myApp.auth().useDeviceLanguage();


export { projectFirestore,projectStorage, projectAuth, FireStore, projectFunctions, projectAuthVer, fieldValue}; 