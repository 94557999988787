import { useNotificationStore } from '@dafcoe/vue-notification'
const { setNotification } = useNotificationStore()

const push_error_notification = (msg, duration=5000) => {
    setNotification({
        "message": msg,
        "type":"alert",
        "showIcon": true,
        "dismiss": {
            "manually": true,
            "automatically": true
        },
        "duration":  duration,
        "showDurationProgress": true,
        "appearance": "glass"
    })
}

const push_success_notification = (msg, duration) => {
    setNotification({
        "message": msg,
        "type":"success",
        "showIcon": true,
        "dismiss": {
            "manually": true,
            "automatically": true
        },
        "duration": duration || 5000,
        "showDurationProgress": true,
        "appearance": "glass"
    })
}

const push_info_notification = (msg, duration) => {
    setNotification({
        "message": msg,
        "type":"info",
        "showIcon": true,
        "dismiss": {
            "manually": true,
            "automatically": true
        },
        "duration": duration || 5000,
        "showDurationProgress": true,
        "appearance": "glass"
    })
}

export{push_error_notification, push_success_notification, push_info_notification}