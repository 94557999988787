
import { projectFirestore, FireStore } from '../firebase/config';
import store from '../store';

var logs_collection = projectFirestore.collection('Logs');

var log = {
    user: null,
    timer: null,
    date: null,
    operation: null,
    pallet_data: null,
    status: 'new',
    field: null,
    makat: null,
    uid: null,
    old_value: null,
    new_value: null
}

const fromStickers_log = async (title, item, amt, new_value = null, mission = null, auto=false) => {
    console.log('log item', item);
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date();
    log.operation = title;
    log.item_data = item;
    log.amt = amt;
    log.mission = mission;
    log.user = auto ? 'מערכת' : store.state.user.name;
    log.user_id = auto ? 0 : store.state.user.uid;
    log.new_value = new_value;
    console.log('fromStickers_log', log);
    
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}
const topu_log = async (title, item, amt, new_value = null, mission = null, auto=false) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date();
    log.operation = title;
    log.item_data = item;
    log.amt = amt;
    log.mission = mission;
    log.user = auto ? 'מערכת' : store.state.user.name;
    log.user_id = auto ? 0 : store.state.user.uid;
    log.new_value = new_value;
    console.log('topu_log', log);
    
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}
const invBal_log = async (title, item, amt, new_value = null, mission = null, auto=false) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date();
    log.operation = title;
    log.item_data = item;
    log.amt = amt;
    log.mission = mission;
    log.user = auto ? 'מערכת' : store.state.user.name;
    log.user_id = auto ? 0 : store.state.user.uid;
    log.new_value = new_value;
    console.log('inv_bal', log);
    
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const login_log = async (usr, name, time, location) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(time);
    log.operation = 'התחברות';
    log.user = name
    log.user_id = usr
    log.location = location
    console.log('login log', log);
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const persist_login_log = async (usr, name, time, location) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(time);
    log.operation = 'המשך שימוש';
    log.user = name;
    log.user_id = usr;
    log.location = location
    console.log('persist login log', log);
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const pincode_log = async (usr, name, time) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(time);
    log.operation = 'קוד זיהוי חוסר פעילות';
    log.user = name;
    log.user_id = usr;
    console.log('persist login log', log);
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const search_item_log = async (makat, pallet) => {
    console.log('Search Item - log.');
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.makat = makat;
    log.operation = 'חיפוש פריט'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.old_value = null; //H
    log.new_value = null; //H
    log.field = null;
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const change_pallet_location_log = async (pallet, old_value, new_value, makat) => {
    console.log('Change loc - log.');
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.makat = makat;
    log.operation = 'שינוי איתור'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.old_value = old_value; //H
    log.new_value = new_value; //H
    log.field = null;
    await update_history_in_current_log(pallet, {
        date: log.date,
        operation: log.operation,
        user: log.user,
        old_value: log.old_value,
        new_value: log.new_value
    })
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

// Removed History
const delete_pallet_log = async (pallet) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'מחיקת משטח'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.old_value = pallet.path; //H
    log.new_value = 'נמחק' //H
    log.field = null;
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const create_new_temp_pallet_log = async (pallet) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'משטח זמני חדש'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.old_value = ''; //H
    log.new_value = pallet.id; //H
    log.field = null;
    await doc.set(log);
    await update_history_in_current_log(pallet, {
        date: log.date,
        operation: log.operation,
        user: log.user,
        old_value: log.old_value,
        new_value: log.new_value
    })
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const update_pallet_log = async (pallet, field, old_value, new_value) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date();  //H
    log.operation = 'עדכון משטח'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.field = field;
    log.old_value = old_value; //H
    log.new_value = new_value //H
    await doc.set(log);
    await update_history_in_current_log(pallet, {
        date: log.date,
        operation: log.operation,
        user: log.user,
        old_value: log.old_value,
        new_value: log.new_value
    })
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const change_pu_location_log = async (item, old_value, new_value) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'שינוי מיקום פיקאפ'; //H
    log.pallet_data = item;
    log.user = store.state.user.name; //H
    log.field = null;
    log.old_value = old_value //H
    log.new_value = new_value //H
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const new_pu_log = async (item, new_path) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'פיקאפ חדש למוצר'; //H
    log.pallet_data = item;
    log.user = store.state.user.name; //H
    log.field = null;
    log.old_value = " "; //H
    log.new_value = new_path; //H
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const move_to_hisul_log = async (item, amount) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'העברה ל40.A'; //H
    log.pallet_data = item;
    log.user = store.state.user.name; //H
    log.field = null;
    log.old_value = 0; //H
    log.new_value = amount; //H
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}

// Removed History
const rikun_log = async (pallet, old_amount, new_amount, pu_path) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'ריקון משטח'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.field = 'ל ' + pu_path;
    log.old_value = old_amount; //H
    log.new_value = new_amount + `(-${old_amount - new_amount})`; //H
    await doc.set(log);
    if (log.new_value) {
        if (isNumeric(log.new_value)) {
            await update_history_in_current_log(pallet, {
                date: log.date,
                operation: log.operation,
                user: log.user,
                old_value: log.old_value,
                new_value: log.new_value
            })
        } else {
            if (log.pallet_data && log.pallet_data.amount > 0) {
                await update_history_in_current_log(pallet, {
                    date: log.date,
                    operation: log.operation,
                    user: log.user,
                    old_value: log.old_value,
                    new_value: log.new_value
                })
            }
        }
    }
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const update_pallet_notes_log = async (pallet, old_val) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'עדכון הערה למשטח'; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.field = null;
    log.old_value = old_val || ''; //H
    log.new_value = pallet.notes; //H
    await doc.set(log);
    await update_history_in_current_log(pallet, {
        date: log.date,
        operation: log.operation,
        user: log.user,
        old_value: log.old_value,
        new_value: log.new_value
    })
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const lock_unlock_pallet_log = async (pallet) => {
    var op = '';
    if (pallet.locked) {
        op = 'נעילת משטח'
    } else {
        op = 'שחרור נעילה'
    }
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = op; //H
    log.pallet_data = pallet;
    log.user = store.state.user.name; //H
    log.field = null;
    log.old_value = !pallet.locked; //H
    log.new_value = pallet.locked; //H
    await doc.set(log);
    await update_history_in_current_log(pallet, {
        date: log.date,
        operation: log.operation,
        user: log.user,
        old_value: log.old_value,
        new_value: log.new_value
    })
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}

const boxbarcode_log = async (item, new_value) => {
    const doc = logs_collection.doc();
    log.uid = doc.id;
    log.date = new Date(); //H
    log.operation = 'הגדר ברקוד קרטון'; //H
    log.pallet_data = item;
    log.user = store.state.user.name; //H
    log.field = null;
    log.old_value = ' '; //H
    log.new_value = new_value; //H
    await doc.set(log);
    log = {
        user: null,
        timer: null,
        date: null,
        operation: null,
        pallet_data: null,
        status: 'new',
        field: null,
        makat: null,
        uid: null,
        old_value: null,
        new_value: null
    }
    return true
}


const update_history_in_current_log = async (pallet, data) => {
    // projectFirestore.collection('Locations').doc(pallet.path).collection('Pallets').doc(pallet.id).add to array
    try {
        console.log('pallet', pallet);
        console.log('data', data);
        if(pallet.path){
            const check = await projectFirestore.collection('Locations').doc(pallet.path).collection('Pallets').doc(pallet.id).get();
            if (check.exists) {
                if (!check.data().item || !check.data().item_data) {
                    return
                }
                if (pallet.history) {
                    await projectFirestore.collection('Locations').doc(pallet.path).collection('Pallets').doc(pallet.id)
                        .update({
                            history: FireStore.FieldValue.arrayUnion(data)
                        })
                } else {
                    await projectFirestore.collection('Locations').doc(pallet.path).collection('Pallets').doc(pallet.id)
                        .set({
                            history: [data]
                        }, { merge: true })
                }
            }
        }else{
            //check if it is a temp pallet
            const temp_check = await projectFirestore.collection('Temp_Pallets').doc(pallet.id).get();
            if (temp_check.exists) {
                if (pallet.history) {
                    await projectFirestore.collection('Temp_Pallets').doc(pallet.id)
                        .update({
                            history: FireStore.FieldValue.arrayUnion(data)
                        })
                } else {
                    await projectFirestore.collection('Temp_Pallets').doc(pallet.id)
                        .set({
                            history: [data]
                        }, { merge: true })
                }
            }
        }

    } catch (err) {
        console.log(err.message);
    }
}

export {
    change_pallet_location_log, delete_pallet_log, create_new_temp_pallet_log, update_pallet_log
    , move_to_hisul_log, rikun_log, boxbarcode_log, new_pu_log, change_pu_location_log,
    update_pallet_notes_log, lock_unlock_pallet_log, search_item_log, login_log, persist_login_log, pincode_log, topu_log, fromStickers_log, invBal_log
}