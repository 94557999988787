<template>
  <div class="home">
    <div class="log-in">
      <div class="wms-title">
        <p>WMS-ONE</p>
        <p style="font-size:16px; color:var(--success)">מחסן חכם לעסק חכם</p>
      </div>

      <img src="@/assets/logo_120.png" alt="" class="logo">

      <div class="log-in-form" v-if="!user">
        <Login/>
      </div>

      <div class="logged-in" v-if="user">
        <UpdateProfile v-if="!user.name"/>
        <div class="continue-user" v-if="user.name">
          <p class="greeting-user">ברוך הבא {{user.name}}</p>
          <el-button type="success" style="width:80%; height:50px; font-size:18px; text-shadow: 0 0 2px black;" @click="go_main">המשך למערכת</el-button>
          <el-button type="danger" style="width:80%; height:50px; font-size:18px; text-shadow: 0 0 2px black; margin:0; margin-top: 10px;" @click="log_out">החלף משתמש</el-button>
        </div>
      </div>

      <div class="login-footer">
        <p>פותח ע"י CyberPark</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import store from '../store';
import Login from '../components/Auth/Login.vue';
import UpdateProfile from '../components/Auth/UpdateProfile.vue';
import {log_out} from '../functions/User';
import router from '../router';


export default {
  name: 'Home',
  components:{Login, UpdateProfile},
  setup(){
    const user = ref(computed(() => {
      return store.state.user
    }))

    const step = ref(0)
    
    const go_main = ()=> {
      router.push('/wms/')
    }


    return{
      user, step, log_out, go_main
    }
  }
}
</script>

<style scoped>
.home{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.log-in{
  width: 100%;
  height: 100%;
  max-width: 380px;
  max-height: 600px;
  background: var(--container);
  border-radius: 10px;
  box-shadow: var(--boxshadow);
  flex-shrink: 0;
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.wms-title{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 24px;
  color: #fcc87f;
  flex-direction: column;
  text-shadow: 0 0 3px black;
}
.login-footer{
  width: 100%;
  height: 50px;
  background: var(--btnBg);
  margin-top: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.664);
  font-size: 14px;
  position: relative;
}
.logo{
  max-width: 100px;
  height: auto;
  margin: auto;
  border-radius: 50%;
  margin-top: 10px;
  box-shadow: var(--boxshadow);
}
.log-in-form{
  width: 100%;
  height: calc(100% - 50px - 50px);
  padding: 5px;
}

.logged-in{
  width: 100%;
  height: calc(100% - 50px - 50px);
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.continue-user{
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
.greeting-user{
  width: 100%;
  height: fit-content;
  padding: 5px;
  overflow: hidden;
  color: #fcc87f;
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
  text-shadow: 0 0 3px black;
}
</style>