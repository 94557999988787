import { projectAuth } from "../firebase/config";
import store from "../store";

projectAuth
let intervalRef = null;
let elapsedMinutes = 0;

const resetTimer = (flag = false) => {
  if ((elapsedMinutes < store.state.time_out) || flag == true) {
    // console.log('reset'); oren remove cmt
    store.state.is_idle = false
    elapsedMinutes = 0;
  } else {
    return
  }
};

const removeEventListeners = () => {
  document.removeEventListener('mousemove', resetTimer);
  document.removeEventListener('mousedown', resetTimer);
  document.removeEventListener('keypress', resetTimer);
  document.removeEventListener('touchmove', resetTimer);
  document.removeEventListener('scroll', resetTimer);
};

const setupInactivityTimer = (minutes = 5) => {
  console.log('minutes', minutes);
  // Clear any existing interval
  if (intervalRef) {
    clearInterval(intervalRef);
  }
  
  removeEventListeners();
  
  // Set up event listeners to detect activity
  document.addEventListener('mousemove', resetTimer, false);
  document.addEventListener('mousedown', resetTimer, false);
  document.addEventListener('keypress', resetTimer, false);
  document.addEventListener('touchmove', resetTimer, false);
  document.addEventListener('scroll', resetTimer, false);

  // Start interval to count elapsed time
  intervalRef = setInterval(async () => {
    console.log(elapsedMinutes);
    elapsedMinutes++;
    if (elapsedMinutes - minutes == -1) {
      store.state.is_idle = true
      console.log(store.state.is_idle);
    }
    if (elapsedMinutes >= minutes) {
      console.log('Idle Triggered');
    }
  }, 1000 * 60);
};

const stopInactivityTimer = () => {
  if (intervalRef) {
    clearInterval(intervalRef);
  }

  // Reset elapsed time
  elapsedMinutes = 0;

  // Remove activity event listeners
  document.removeEventListener('mousemove', resetTimer);
  document.removeEventListener('mousedown', resetTimer);
  document.removeEventListener('keypress', resetTimer);
  document.removeEventListener('touchmove', resetTimer);
  document.removeEventListener('scroll', resetTimer);
};

export { setupInactivityTimer, stopInactivityTimer, resetTimer };
