
import { computed, ref } from 'vue';
import { projectAuth, projectFirestore, projectFunctions } from '../firebase/config';
import store from '../store';
import { push_error_notification, push_success_notification } from './Msgs';
import { resetTimer } from './useIdleDetector';
import { pincode_log } from './Melaket_logs';

const user = ref(computed(() => {
    return store.state.user
}))

const log_out = async () => {
    try {
        await projectAuth.signOut()
        console.log('User logged out!');
        setTimeout(() => {
            location.reload();
        }, 200);
    } catch (err) {
        throw new Error(err)
    }
}

const check_user_code = async (code) => {
    if (!store.state.user?.code) {
        console.log('No code, you have been logged out.');
        log_out()
    } else if (code == store.state.user.code) {
        resetTimer(true);
        push_success_notification('קוד נכון!')

        try {
            console.log('unlocking in db');
            const res = await projectFunctions.httpsCallable('profile_locked')({ user: store.state.user.uid, locked: false });
            if (res.data === true) {
                console.log('user unlocked successfully')
            } else {
                console.log('functions error in unlocking user', res)
            }
        } catch (error) {
            console.log('failed to update db', error);
        }

        const now = new Date().getTime();
        pincode_log(store.state.user.uid, store.state.user.name, now)
    } else {
        push_error_notification('קוד שגוי!')
        return false
    }
}

const set_days_for_logout = async (days_number) => {
    try {
        await projectFirestore.collection('Utils').doc('Sys')
            .set({
                min_to_dis: days_number
            }, { merge: true })
    } catch (err) {
        throw new Error(err)
    }
}

const get_days_for_logout_from_db = async () => {
    try {
        const doc = await projectFirestore.collection('Utils').doc('Sys').get()
        return doc.data().min_to_dis
    } catch (err) {
        throw new Error(err)
    }
}

const is_idle = ref(computed(() => {
    return store.state.is_idle
}))

const time_out_minutes_logout = ref(computed(() => {
    return store.state.time_out
}))

const is_user_online = async (user_uid, state) => {
    try {
        await projectFunctions.httpsCallable("userLoggedOut")({ user_uid, state });
    } catch (err) {
        console.log(err.message);
    }
}

const change_idle_activity = async (user) => {
    try {
        await projectFunctions.httpsCallable("user_activity_idle")({ ...user });
    } catch (err) {
        console.log(err.message);
    }
}

export {
    log_out,
    set_days_for_logout,
    get_days_for_logout_from_db,
    user,
    is_idle,
    time_out_minutes_logout,
    is_user_online,
    change_idle_activity,
    check_user_code
}