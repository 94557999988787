<template>
    <div class="loading-main">
        <img class="spinner" src="@/assets/spinner.gif" alt="">
        <p style="color:gold; margin-bottom:5px;">WMS1.co.il</p>
        <p>טוען</p>
    </div>
</template>

<script>
export default {
setup(){

    return{

    }
}
}
</script>

<style scoped>
.loading-main{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 2px black;
}
.spinner{
    max-width: 370px;
    height: auto;
    max-height: 370px;
}
</style>