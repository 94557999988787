<template>
    <div class="update-profile-new">
        <div class="update-title">
            <i class="ri-profile-line" style="margin-left:5px;"></i>
            <p>עדכון פרופיל</p>
        </div>
        <div class="form-input-title">
            <i class="ri-phone-line" style="margin-left:5px;"></i>
            <p>שם פרטי</p>
        </div>
        <div class="form-input">
            <el-input v-model="profile_data.name" placeholder="שם פרטי" type="text" name="name" :input-style="input_style" maxlength="15"/>
        </div>

        <div class="form-input-title">
            <i class="ri-phone-line" style="margin-left:5px;"></i>
            <p>שם משפחה</p>
        </div>
        <div class="form-input">
            <el-input v-model="profile_data.last_name" placeholder="שם משפחה" type="text" name="name" :input-style="input_style" maxlength="15"/>
        </div>

        <div class="form-input-title">
            <i class="ri-phone-line" style="margin-left:5px;"></i>
            <p>תעודת זהות</p>
        </div>
        <div class="form-input">
            <el-input v-model="profile_data.id" placeholder="תעודת זהות" type="tel" name="id" :input-style="input_style" maxlength="15"/>
        </div>

        <el-button type="primary" :disabled="enable_next" @click="update_profile">המשך</el-button>
    </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { projectFunctions } from '../../firebase/config'
import store from '../../store'
export default {
setup(){
    const input_style = {
        'border': 'none',
        'background': 'var(--btnBg)',
        'font-size': '18px',
        'color' : 'white'
    }
    
    const profile_data = ref({
        name: '',
        last_name: '',
        id: ''
    })

    const enable_next = ref(computed(() => {
        if(profile_data.value.name.length >= 2 && profile_data.value.last_name.length >= 2 && profile_data.value.id.length >= 9 ){
            return false
        }else{
            return true
        }
    }))

    const update_profile = async () => {
        store.state.user.name = profile_data.value.name;
        store.state.user.last_name = profile_data.value.last_name;
        store.state.user.id = profile_data.value.id;
        
        await projectFunctions.httpsCallable("create_profile")({profile: profile_data.value})
    }

    return{
        input_style, profile_data, enable_next, update_profile
    }

}
}
</script>

<style scoped>
.update-profile-new{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.update-title{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    color: whitesmoke;
    font-size: 24px;
    text-shadow:  0 0 2px black;
}

.form-input-title{
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin-top: 10px;
    color: #fcc87f;
    text-shadow: 0 0 3px black;
    margin-bottom: 5px;
    overflow: hidden;
}
.form-input{
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
}
</style>